import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {derived_properties: {color: { in: "vita" }}}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				color
				elastic
				form
				waxed
				the_length
			}
			
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "vita-skosnören"
    }}>{`Vita Skosnören`}</h1>
    <p>{`Vita skosnören kan smälta in väldigt bra i ett par vita skor, allting ser ut att höra ihop. Men man kan även matcha ett par vita skosnören med nästan vilka skor som helst. På ett par riktigt färgglada skor så kan ett par vita skosnören sticka ut och på ett par svarta skor kan den vita färgen ge en bra kontrast. Tänk dock på att vita skosnören snabbt kan blu smutsiga och ge ett sämre intryck. För att underlätta tvättning och färhindra att smuts fastnar kan man välja ett par vaxade skosnören. Nedan hittar du vita skosnören i alla dess former, länger och nyanser.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      